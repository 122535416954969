import { render, staticRenderFns } from "./add.vue?vue&type=template&id=778fa9b6&"
import script from "./add.vue?vue&type=script&lang=js&"
export * from "./add.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./add.vue?vue&type=custom&index=0&blockType=b-form-input&name=prix&min=1&value=%60%24%7Bprice%7D%60&%3Amax=5"
if (typeof block0 === 'function') block0(component)

export default component.exports